"use client";

import { WebViewerInstance, type WebViewerOptions } from "@pdftron/webviewer";
import { use, useEffect, useRef, useState, type RefObject } from "react";
import { ApryseConfigurationContext } from "./apryse-configuration-provider";

export type FileDetails = {
  name: string;
  pdfUrl?: string;
};

type ApryseOptions = {
  /** Prevents loading or closing a document while true */
  pause?: boolean;
};

export function useApryse(
  fileDetails?: FileDetails,
  options?: ApryseOptions,
): [RefObject<HTMLDivElement | null>, { isDocumentLoaded: boolean }] {
  const baseOptions = use(ApryseConfigurationContext);
  const initialized = useRef(false);
  const ref = useRef<HTMLDivElement>(null);
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);
  const [current, setCurrent] = useState<FileDetails>();
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);
  const pause = options?.pause ?? false;

  useEffect(() => {
    // Don't re-run effect in dev strict mode
    if (initialized.current) return;
    if (!ref.current) return;

    initialized.current = true;
    const element = ref.current;

    async function instantiateWebViewer() {
      const options = {
        ...baseOptions,
        ui: "beta",
        isReadOnly: true,
      } satisfies WebViewerOptions;

      const { default: WebViewer } = await import("@pdftron/webviewer");
      const webViewer = await WebViewer(options, element);
      const { Core, UI } = webViewer;
      const { documentViewer, Tools } = Core;
      const { LayoutMode } = webViewer.UI;

      const topHeader = new UI.Components.ModularHeader({
        dataElement: "default-top-header",
        placement: "top",
        grow: 1,
        gap: 12,
        position: "start",
        justifyContent: "space-between",
        style: { padding: "2px" },
        items: [
          new UI.Components.GroupedItems({
            alwaysVisible: true,
            dataElement: "left-group",
            items: [
              new UI.Components.ToolButton({
                dataElement: "panToolButton",
                toolName: Tools.ToolNames.PAN,
              }),
            ],
          }),

          new UI.Components.Zoom(),

          new UI.Components.GroupedItems({
            alwaysVisible: true,
            dataElement: "right-group",
            items: [
              new UI.Components.PresetButton({
                dataElement: "print-button",
                buttonType: "printButton",
              }),
              new UI.Components.PresetButton({
                dataElement: "download-button",
                buttonType: "downloadButton",
              }),
            ],
          }),
        ],
      });

      UI.setModularHeaders([topHeader]);

      documentViewer.addEventListener("documentLoaded", function () {
        webViewer.UI.setLayoutMode(LayoutMode.Continuous);
        webViewer.UI.setToolMode(Tools.ToolNames.PAN);
        setIsDocumentLoaded(true);
      });

      documentViewer.addEventListener("documentUnloaded", function () {
        setIsDocumentLoaded(false);
      });

      setInstance(webViewer);
    }

    instantiateWebViewer();
  }, [baseOptions]);

  useEffect(() => {
    if (!instance) return;
    if (pause) return;
    if (fileDetails === current) return;

    setCurrent(fileDetails);
    setIsDocumentLoaded(false);

    const { loadDocument, closeDocument } = instance.UI;

    if (fileDetails && fileDetails.pdfUrl) {
      loadDocument(fileDetails.pdfUrl, {
        extension: "pdf",
        filename: fileDetails.name,
      });
    } else {
      closeDocument();
    }
  }, [instance, current, pause, fileDetails]);

  return [ref, { isDocumentLoaded }];
}
