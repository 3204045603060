"use client";

import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Button } from "~/ui/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "~/ui/dialog";

/**
 * Current version of the app that is running
 */
let currentAppVersion: string | null = null;

/**
 * Default interval time in seconds to check for new version
 */
const DEFAULT_CHECK_INTERVAL_SECONDS = 120;

function useVersionChecker({
  intervalSeconds = DEFAULT_CHECK_INTERVAL_SECONDS,
} = {}) {
  const intervalMs = intervalSeconds * 1000;

  return useQuery({
    queryKey: ["version-checker"],
    refetchInterval: intervalMs,
    refetchIntervalInBackground: true,
    queryFn: async () => {
      try {
        const response = await fetch("/api/version");
        const fetchedVersion = await response.text();

        // Store previous version before updating
        const previousVersion = currentAppVersion;

        // Update current version
        currentAppVersion = fetchedVersion;

        return {
          current: fetchedVersion,
          previous: previousVersion,
          hasNewVersion:
            previousVersion !== null && fetchedVersion !== previousVersion,
        };
      } catch (error) {
        console.error("Failed to check for new version:", error);
        return {
          current: currentAppVersion,
          previous: null,
          hasNewVersion: false,
        };
      }
    },
  });
}

export function VersionUpdater(options?: { intervalSeconds?: number }) {
  const { data } = useVersionChecker(options);
  const [isUpdateAcknowledged, setIsUpdateAcknowledged] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Handle new version detection
  useEffect(() => {
    if (data?.hasNewVersion) {
      setIsUpdateAcknowledged(false);
      setIsDialogOpen(true);
    }
  }, [data?.hasNewVersion]);

  // If no new version or update acknowledged, render nothing
  if (!data?.hasNewVersion || isUpdateAcknowledged) {
    return null;
  }

  const handleDismiss = () => {
    setIsDialogOpen(false);
    setIsUpdateAcknowledged(true);
  };

  const handleUpdate = () => {
    window.location.reload();
  };

  return (
    <Dialog
      size="md"
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
    >
      <DialogTitle>New version available</DialogTitle>
      <DialogDescription>
        A new version of the app is available. It is recommended to refresh the
        page to get the latest updates and avoid any issues.
      </DialogDescription>
      <DialogActions>
        <Button variant="outline" onClick={handleDismiss}>
          Later
        </Button>
        <Button onClick={handleUpdate}>Reload and update</Button>
      </DialogActions>
    </Dialog>
  );
}
