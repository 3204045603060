"use client";

import { twMerge } from "tailwind-merge";
import { useApryse } from "~/components/apryse-webviewer-v2";
import type { FileDetails } from "./use-apryse";

type ApryseViewerProps = {
  fileDetails: FileDetails;
  className?: string;
};

export function ApryseViewer({ fileDetails, className }: ApryseViewerProps) {
  const [apryseRef] = useApryse(fileDetails);

  return (
    <div ref={apryseRef} className={twMerge("h-full w-full", className)} />
  );
}
