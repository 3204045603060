"use client";

import { createContext, useState } from "react";

type ApryseConfigurationContextType = {
  path: string;
  css: string;
  licenseKey?: string;
};

const defaultApryseConfigurationContext = {
  path: "/webviewer",
  css: "/assets/apryse.css",
};

export const ApryseConfigurationContext =
  createContext<ApryseConfigurationContextType>(
    defaultApryseConfigurationContext,
  );

type ApryseConfigurationProviderProps = {
  css?: string;
  path?: string;
  licenseKey?: string;
  children: React.ReactNode;
};

export function ApryseConfigurationProvider({
  css,
  path,
  licenseKey,
  children,
}: ApryseConfigurationProviderProps) {
  const [config] = useState<ApryseConfigurationContextType>({
    css: css ?? defaultApryseConfigurationContext.css,
    path: path ?? defaultApryseConfigurationContext.path,
    licenseKey,
  });

  return (
    <ApryseConfigurationContext.Provider value={config}>
      {children}
    </ApryseConfigurationContext.Provider>
  );
}

ApryseConfigurationProvider.displayName = "ApryseConfigurationProvider";
